import type { ComponentPropsWithoutRef, ReactNode } from "react";

import placeholderImg from "~/assets/images/fallback/main-image-fallback.png";
import { cn } from "~/utils/classnames";
import { tailwindTheme } from "~/utils/theme";

interface BaseImageProps extends Omit<ComponentPropsWithoutRef<"img">, "src"> {
  src: string | null | undefined;
  alt: string;
  breakpoints?: {
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
    "2xl"?: string;
  };
  containerClassName?: string;
  priority?: boolean; // New prop to control lazy loading
}

type Fallback = { fallbackSrc?: string } | { fallbackElement?: ReactNode };

type ImageProps = BaseImageProps & Fallback;

export function links() {
  return [
    {
      rel: "preload",
      href: placeholderImg,
      as: "image",
    },
  ];
}

const ImageComponent = ({
  src,
  breakpoints,
  alt,
  priority = false,
  className,
  containerClassName,
  ...props
}: ImageProps) => {
  const { fallbackSrc, fallbackElement, ...propsRest } = {
    fallbackSrc: undefined,
    fallbackElement: undefined,
    ...props,
  };

  const tailwindBreakpointsMinus1 = Object.entries(tailwindTheme.screens).reduce(
    (acc, [key, value]) => {
      const valueAsNumber = parseInt(value.replace("px", ""), 10);
      acc[key] = `${valueAsNumber - 1}px`;
      return acc;
    },
    {} as Record<string, string>
  );

  return breakpoints ? (
    <picture className={containerClassName} {...propsRest}>
      {Object.entries(breakpoints).map(([key, value]) =>
        key && value ? (
          <source
            key={key}
            media={`(max-width: ${tailwindBreakpointsMinus1[key as keyof typeof tailwindBreakpointsMinus1]})`}
            srcSet={value}
          />
        ) : null
      )}
      <img
        src={src || ""}
        alt={alt || ""}
        className={cn("h-full w-full", className)}
        loading={priority ? "eager" : "lazy"}
        {...propsRest}
      />
    </picture>
  ) : (
    <img
      src={src || placeholderImg}
      alt={alt}
      className={cn(className)}
      loading={priority ? "eager" : "lazy"}
      {...propsRest}
    />
  );
};

export { ImageComponent as Image };

import typographyPlugin from "@tailwindcss/typography";
import type { Config } from "tailwindcss";
import colors from "tailwindcss/colors";

export default {
  content: ["./app/**/*.{js,jsx,ts,tsx,mdx}", "./.storybook/**/*.{js,jsx,ts,tsx,mdx}"],
  future: {
    hoverOnlyWhenSupported: true,
  },
  theme: {
    extend: {
      colors: {
        grey: colors.zinc,
        gray: colors.zinc,
        primary: {
          DEFAULT: "#ffcc00",
          50: "#fffee7",
          100: "#fffec1",
          200: "#fff886",
          300: "#ffec41",
          400: "#ffdb0d",
          500: "#ffcc00",
          600: "#d19500",
          700: "#a66a02",
          800: "#89530a",
          900: "#74430f",
          950: "#442304",
        },
        success: colors.green,
        warning: colors.amber,
        danger: colors.red,
        info: colors.sky,
      },
      // TODO: Remove this
      transition: {
        short: "all 0.15s cubic-bezier(0.6, 0.15, 0.25, 1)",
        medium: "all 0.2s cubic-bezier(0.6, 0.15, 0.25, 1)",
        long: "all 0.3s cubic-bezier(0.6, 0.15, 0.25, 1)",
      },
      // ---
      animation: {
        "spin-slow": "spin 1.25s linear infinite",
      },
      keyframes: {
        "fade-in": {
          "0%": { opacity: "0" },
          "100%": { opacity: "1" },
        },
      },
      aria: {
        invalid: 'invalid="true"',
      },
      screens: {
        "2xl": "1440px",
      },
    },

    // Not extended
    fontFamily: {
      main: ["Open Sans", "system-ui", "sans-serif"],
    },
    typography: {
      DEFAULT: {
        css: {
          maxWidth: "100ch",
        },
      },
    },
  },
  variants: {
    extend: {
      backgroundColor: ["disabled"],
    },
  },
  plugins: [typographyPlugin],
} satisfies Config;

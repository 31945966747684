import { useEffect } from "react";
import type { AnalyticsEvent } from "~types/app/analytics-session.types";

function pageview(url: string, trackingId: string) {
  if (!window.gtag || !window.gaEnabled) {
    console.warn(`Analytics Pageview not sent because gtag is not loaded`, [
      "config",
      trackingId,
      { page_path: url },
    ]);
    return;
  }
  console.info(`Analytics Pageview sent`, ["config", trackingId, { page_path: url }]);
  window.gtag("config", trackingId, {
    page_path: url,
  });
}

function event({ action, data }: { action: string; data: any }) {
  if (!window.gtag || !window.gaEnabled) {
    console.warn(`Analytics Event not sent because gtag is not loaded`, ["event", action, data]);
    return;
  }
  console.info(`Analytics Event sent`, ["event", action, data]);
  window.gtag("event", action, data);
}

export const analytics = { pageview, event };

/** Send analytics events on page load. */
export function useAnalyticsEvents({
  pageLoadEvents,
}: {
  pageLoadEvents?: Array<AnalyticsEvent> | null | undefined;
}) {
  useEffect(() => {
    if (pageLoadEvents && pageLoadEvents.length > 0) {
      setTimeout(() => {
        pageLoadEvents.forEach((event) =>
          analytics.event({ action: event.action, data: event.data })
        );
        return;
      }, 0);
    }
  }, [pageLoadEvents]);
}
